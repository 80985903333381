<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="mt-n10">
      <v-col cols="8" sm="6" md="6" lg="6" xl="6" offset-sm="1" class="
          text-body-2 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3
          ps-9
          px-sm-16
          font-weight-medium
          mt-10 mt-sm-0
        " :class="`text-body-1 font-weight-bold`" :align-self="$vuetify.breakpoint.smAndUp ? 'center' : ''">
        <div v-if="!loading">
          <v-row class="mt-12 mt-sm-7 mt-lg-0" v-if="isPaymentSuccess">
            <v-col cols="12">
              <v-img src="../assets/payment-success.svg" :height="$vuetify.breakpoint.xl
                ? 300
                : $vuetify.breakpoint.lg
                  ? 200
                  : $vuetify.breakpoint.smAndUp
                    ? 120
                    : 80
                " contain position="center" class="mb-4" />

              <v-row class="success--text" justify="center">Payment Successful</v-row>
              <v-row justify="center" class="mt-2 mt-md-4">
                Welcome to<span class="secondary--text mx-2"> Onethird</span>
                family.
              </v-row>
              <v-row justify="center" class="mt-2 mt-md-4 violet--text">
                نتشرف بخدمتكم🥰
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-12 mt-sm-0" v-else>
            <v-col cols="12">
              <v-img src="../assets/payment-failed.png" :height="$vuetify.breakpoint.xl
                ? 300
                : $vuetify.breakpoint.lg
                  ? 200
                  : $vuetify.breakpoint.smAndUp
                    ? 120
                    : 80
                " contain position="center" />

              <v-row class="red--text" justify="center"> Payment Failed </v-row>
              <v-row justify="center" class="
                  mt-md-4
                  text-caption text-sm-body-1 text-md-h5 text-lg-h4 text-xl-h3
                ">
                Please contact out customer support.
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="mt-7">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-row>
        </div>
      </v-col>
      <v-col cols="4" sm="5" md="5" lg="5" xl="5" class="pe-n16 mt-16 mt-sm-0">
        <v-img class="mt-16 mt-sm-10" src="" :height="$vuetify.breakpoint.xl
          ? 880
          : $vuetify.breakpoint.smAndUp
            ? 706
            : ''
          " contain position="right" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  created() {
    var url = new URL(window.location.href);

    if (url) {
      const tapId = url.searchParams.get("tap_id");
      if (tapId) {
        let type =
          this.$route.name == "InvoicePaymentThankYou" ? "INVOICE" : "CHECKOUT";
        this.getPaymentStatus(tapId, type);
      }
    }
  },
  methods: {
    getPaymentStatus(gatewayId, type) {
      this.loading = true;
      this.$apollo
        .query({
          query: require("../api/getPaymentStatusByGatewayId.graphql"),
          variables: { gatewayId: gatewayId, type: type }
        })

        .then(data => {
          this.loading = false;
          if (data.data.getPaymentStatusByGatewayId) {
            this.isPaymentSuccess = data.data.getPaymentStatusByGatewayId;
          }
        });
    }
  },
  data() {
    return {
      loading: true,
      isPaymentSuccess: false
    };
  }
};
</script>
